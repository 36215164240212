var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "drawing-mission", attrs: { column: "" } },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "", grow: "" } },
        [
          _c("Drawable", {
            attrs: {
              bgColor: _vm.bg,
              bgImageUrl: _vm.mission.photo,
              width: _vm.width,
              height: _vm.height,
              lineColorRgb: _vm.lineColorRgb,
              lineWidth: _vm.lineWidth,
              canDraw: _vm.canDraw,
              pen: _vm.pen,
              alpha: _vm.alpha,
              points: _vm.points,
            },
            on: { mouseUp: _vm.onMouseUp },
          }),
        ],
        1
      ),
      _vm.isViwerHostLike && _vm.isPictionary
        ? _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _vm.isTeamMission
                ? _c("v-select", {
                    ref: "teamSelect",
                    staticClass: "drawing-mission__select",
                    attrs: {
                      dark: "",
                      value: _vm.assignedTeamId,
                      items: _vm.drawingTeams,
                      "item-text": "name",
                      "item-value": "id",
                      label: "SELECT THE DRAWING TEAM",
                    },
                    on: { input: _vm.updateAssignedTeamId },
                  })
                : _c("v-select", {
                    staticClass: "drawing-mission__select",
                    attrs: {
                      dark: "",
                      value: _vm.assignedUserId,
                      items: _vm.players,
                      "item-text": "firstname",
                      "item-value": "id",
                      label: "SELECT THE DRAWER",
                    },
                    on: { input: _vm.updateAssignedUserId },
                  }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }