<template>
  <v-layout column class="drawing-mission">
    <v-flex d-flex grow>
      <Drawable
        :bgColor="bg"
        :bgImageUrl="mission.photo"
        :width="width"
        :height="height"
        :lineColorRgb="lineColorRgb"
        :lineWidth="lineWidth"
        :canDraw="canDraw"
        :pen="pen"
        :alpha="alpha"
        :points="points"
        @mouseUp="onMouseUp"
      />
    </v-flex>
    <v-flex shrink v-if="isViwerHostLike && isPictionary">
      <v-select
        v-if="isTeamMission"
        dark
        ref="teamSelect"
        class="drawing-mission__select"
        :value="assignedTeamId"
        :items="drawingTeams"
        item-text="name"
        item-value="id"
        label="SELECT THE DRAWING TEAM"
        @input="updateAssignedTeamId"
      />
      <v-select
        v-else
        dark
        class="drawing-mission__select"
        :value="assignedUserId"
        :items="players"
        item-text="firstname"
        item-value="id"
        label="SELECT THE DRAWER"
        @input="updateAssignedUserId"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from "vue"
import { mapState, mapGetters, mapActions } from "vuex"
import { submitDrawingImage } from "@/services/api.service"
import Drawable from "@/components/Drawable/Drawable.vue"
import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"
import User from "@shared/User"
import Team from "@shared/Team"
import { db } from "@/firebase"
import useStore from "@/use/useStore"
import useDrawPoints from "@/use/useDrawPoints"

import { computed, toRefs, ref } from "@vue/composition-api"

export default Vue.extend({
  name: "Drawing",
  components: {
    Drawable
  },
  props: {
    width: Number,
    height: Number,
    mission: Object,
    bg: String
  },

  setup(props) {
    const teamSelect = ref()
    const { store } = useStore()
    const { mission } = toRefs(props)

    const roomId = computed(() => store.state.gameID)
    const viewer = computed(() => store.state.auth.user)
    const assignedTeamId = computed(() => store.state.drawing.assignedTeamId)
    const isPictionary = computed(
      () => mission.value.behavior === MissionType.DrawingPictionary
    )

    const viewerTeamId = computed(() => {
      if (store.getters["group/canViewerSelectGlobalTeam"]) {
        return store.getters["group/globalTeamID"]
      } else {
        return store.state.auth.user?.teamID
      }
    })

    const drawingId = computed(() => `${roomId.value}${mission.value.id}`)
    const sessionId = computed(() => {
      if (isPictionary.value) {
        return assignedTeamId.value
      }

      if (Mission.isTeamPlay(mission.value)) {
        return viewerTeamId.value
      }

      return viewer.value.id
    })

    const { points, sessionRef } = useDrawPoints(drawingId, sessionId)

    function onMouseUp({ points, lineColor, lineWidth }) {
      sessionRef.value.push({
        data: JSON.stringify({ points, lineColor, lineWidth })
      })
    }

    return {
      viewer,
      points,
      drawingId,
      sessionId,
      onMouseUp,
      teamSelect,
      isPictionary,
      assignedTeamId
    }
  },

  data() {
    return {
      db: db.auxiliary()
    }
  },

  computed: {
    ...mapState("drawing", [
      "lineColorRgb",
      "lineWidth",
      "alpha",
      "pen",
      "assignedUserId"
    ]),
    ...mapGetters("auth", ["token"]),
    isTeamMission() {
      return Mission.isTeamPlay(this.mission)
    },
    isViwerHostLike() {
      return (
        this.viewer.id === this.$store.getters.gameStatus?.moderatorId ||
        User.isHost(this.viewer)
      )
    },
    assignedTeamIdRef() {
      return this.db.ref(`drawings/${this.drawingId}/settings/assignedTeamId`)
    },
    canDraw() {
      if (!this.isPictionary && this.$store.getters.missionCompleted)
        return false

      return (
        this.isViwerHostLike ||
        this.viewer?.id === this.sessionId ||
        this.viewer?.teamID === this.sessionId
      )
    },
    drawingTeams() {
      return Team.normalize(this.$store.getters.chats).filter(Team.isSelectable)
    }
  },
  created() {
    this.$bus.$on("drawing-submit", this.onDrawingSubmit)
  },
  beforeDestroy() {
    this.$bus.$off("drawing-submit", this.onDrawingSubmit)
  },
  watch: {
    assignedTeamIdRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onAssignedTeamIdSnapshot)
        newValue?.on("value", this.onAssignedTeamIdSnapshot)
      },
      immediate: true
    },
    assignedUserIdRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onAssignedUserIdSnapshot)
        newValue?.on("value", this.onAssignedUserIdSnapshot)
      },
      immediate: true
    }
  },
  methods: {
    updateAssignedTeamId(teamId) {
      this.assignedTeamIdRef.set(teamId)
      if (this.teamSelect) {
        this.teamSelect.blur()
      }
    },
    onAssignedTeamIdSnapshot(snapshot) {
      this.$store.commit("drawing/SET_ASSIGNED_TEAM_ID", snapshot.val())
    },
    onAssignedUserIdSnapshot(snapshot) {
      console.log("onAssignedUserIdSnapshot")
      this.$store.commit("drawing/SET_ASSIGNED_USER_ID", snapshot.val())
    },
    async onDrawingSubmit(caption = "") {
      const data = await submitDrawingImage({
        drawingId: this.drawingId,
        sessionId: this.sessionId,
        token: this.token
      })

      this.$bus.$emit("drawing-response", {
        caption,
        url: data.downloadURL
      })
    }
  }
})
</script>

<style lang="scss">
.drawing-mission {
  position: relative;
  user-select: none;

  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-input {
    user-select: none;
  }
  .v-select__selection--comma {
    margin: 7px 4px 4px 0;
    font-size: 13px;
  }
  .v-text-field__details {
    display: none;
  }

  &__select {
    width: 60%;
    margin: 20px auto 10px auto;
  }
}
</style>
